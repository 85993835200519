import React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'
import GlobalWrapper from 'components/global-wrapper'
import './404.css'

const Div = styled.div`
  margin: 24px;
`

const NotFoundPage = () => {
  return (
    <GlobalWrapper>
      <Div>
        <div id="wrapper">
          <div id="iphone">
            <div id="shadow"></div>
            <div id="side"></div>
            <div id="lines">
              <div>
                <div>
                  <div></div>
                </div>
              </div>
              <div>
                <div>
                  <div></div>
                </div>
              </div>
            </div>
            <div id="toggler">
              <div></div>
            </div>
            <div id="aux"></div>
            <div id="lightning"></div>
            <div id="bottom-speaker">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div id="skrews">
              <div></div>
              <div></div>
            </div>
            <div id="volume">
              <div></div>
              <div></div>
            </div>
            <div id="front">
              <div id="front-cover"></div>
              <div id="camera">
                <div></div>
              </div>
              <div id="speaker"></div>
              <div id="screen">
                <div id="reminder">
                  <div></div>
                  <div>Lost?</div>
                  <div>now</div>
                </div>
                <div id="circle"></div>
                <div id="time">404</div>
                <div id="date">Error - Page not found</div>
                <div id="bottom"></div>
                <div id="top"></div>
                <div id="slide">
                  <div></div>
                  Return to <a href="/">homepage</a>
                </div>
                <div id="signal">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div id="battery">
                  <div>404%</div>
                  <div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
                <div id="home">
                <div><a href="/" id="zeroinv">0000</a></div>
                </div>
            </div>
          </div>
      </div>
      </Div>
    </GlobalWrapper>
  )
}

export default NotFoundPage
